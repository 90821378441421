<template>
  <TsRow append-class="m-0 justify-between items-center px-4 mb-2 mt-6">
    <TsTypography
      v-if="!isMobile"
      append-class="text-idle-black text-nowrap font-semibold"
    >
      Filters

      <TsIcon name="flowbite:filter-outline" size="20" />
    </TsTypography>

    <TsTypography
      @click="handleClearAll()"
      v-if="
        (!isMobile && searchResultStore.selectedFilterState.length > 0) || selectedRating
      "
      append-class="cursor-pointer underline text-primary font-semibold"
      size="sm"
    >
      Clear all
    </TsTypography>
  </TsRow>
  <TsCard
    :data-testid="`${props.dataTestid}-filter`"
    :append-class="twMerge('bg-white border-[#F1F8FF]', props.appendClass)"
    no-shadow
  >
    <!-- <TsRow
      :class="
        twMerge(
          'justify-between items-center pb-4 border-b mx-0 gap-2',
          isMobile && 'my-3 px-4'
        )
      "
    >
      <TsTypography append-class="font-semibold text-natural-dark-grey" variant="sm">
        Show products available in my area
      </TsTypography>
      <div>
        <TsToggle />
      </div>
    </TsRow> -->
    <TsAccordion
      :data-testid="`${props.dataTestid}-filter-accordion`"
      append-class="mb-0"
      open
    >
      <TsAccordionTab
        v-for="(filter, key, index) in algoliaStore.filters"
        :key="key"
        :data-testid="`${props.dataTestid}-filter-accordion-tab-${key}`"
        :heading="facetFilterLabels[key]"
        :append-class="
          twMerge('gap-0 px-0 border-[#D9E6ED] first:border-t-0', isMobile && 'p-4')
        "
        :body-class="twMerge('px-0 border-[#D9E6ED] pb-4 border-b-0', isMobile && 'px-4')"
        :active="activeTabs[key] || index <= 2"
        @clickTab="toggleTab(key)"
      >
        <template #heading="{ heading, isOpen }">
          <span class="grow flex items-center justify-between">
            <TsTypography as="span" variant="h4" append-class="mb-0">
              {{ heading }}
              <TsTypography as="span" append-class="mb-0"
                >({{ Object.entries(filter).length }})</TsTypography
              >
            </TsTypography>
            <span v-if="isOpen && showSearchBar">
              <TsInput @click.capture="" class="border-2 rounded-full h-9 w-40" />
            </span>
          </span>
        </template>
        <template #icon="{ isOpen }">
          <span class="pl-4 pr-1">
            <Icon
              :class="twMerge(isOpen && 'rotate-90')"
              name="flowbite:angle-right-outline"
              size="20"
            />
          </span>
        </template>
        <div
          :class="{
            'max-h-[10rem] overflow-y-auto': showLess[key],
            'max-h-none': !showLess[key],
          }"
        >
          <div v-for="(value, name, index) in filter" :key="name">
            <TsCheckbox
              v-if="index < 3 || showLess[key]"
              :data-testid="`checkbox-${name}`"
              append-class="my-2"
              size="lg"
              :label="name"
              :value="key + ':' + name"
              :id="name"
              v-model="searchResultStore.selectedFilterState"
              :quantity="value"
            />
            <!-- @change="updateSelectedFilters(key, name, $event.target)" -->
            <!-- :checked="isChecked(key, name)" -->
          </div>
        </div>
        <TsTypography
          v-if="shouldShowMore(key)"
          append-class="text-info underline cursor-pointer mt-4"
          size="sm"
          @click="toggleShowMore(key)"
        >
          {{ showLess[key] ? "Show less" : "Show more" }}
        </TsTypography>
      </TsAccordionTab>

      <TsAccordionTab
        v-if="ratings"
        :data-testid="props.dataTestid + '-rating-accordion-tab'"
        heading="Average Rating  "
        :append-class="
          twMerge('gap-0 px-0 border-[#D9E6ED] first:border-t-0', isMobile && 'p-4')
        "
        :body-class="twMerge('px-0 border-[#D9E6ED] pb-4 border-b-0', isMobile && 'px-4')"
      >
        <div v-for="rating in ratings" :key="rating.rating" class="mt-2">
          <div
            :class="
              twMerge(
                'flex gap-4  hover:font-bold cursor-pointer',
                selectedRating === rating.rating ? 'text-info font-bold' : ''
              )
            "
            @click="handleRatingChange(rating.rating)"
          >
            <TsRatings
              :modelValue="rating.rating"
              :data-testid="`rating-accordion-tsrating-${rating.rating}`"
              readOnly
              size="base"
            />
            <p>{{ rating.text }}</p>
          </div>
        </div>
      </TsAccordionTab>
    </TsAccordion>
  </TsCard>
</template>

<script setup lang="ts">
import { ref, reactive, computed, onMounted, watch } from "vue";
import { twMerge } from "tailwind-merge";
import { useRandomUUID } from "../../composables/useRandomUUID";
import { facetFilterLabels } from "~/types/algolia/algolia.type";

const ratings = [
  { rating: 5, text: useTranslation("fiveStars", "5 stars") },
  { rating: 4, text: useTranslation("fourStars", "4 stars & up") },
  { rating: 3, text: useTranslation("threeStars", "3 stars & up") },
  { rating: 2, text: useTranslation("twoStars", "2 stars & up") },
  { rating: 1, text: useTranslation("oneStar", "1 star & up") },
];

type Props = {
  dataTestid?: string;
  appendClass?: string;
};

const selectedRating = ref<number | null>(null);
const numericFilters = ref<string[] | null>(null);

const handleRatingChange = (rating: number) => {
  if (rating === selectedRating.value) {
    selectedRating.value = null;
    numericFilters.value = null;
    return;
  } else {
    numericFilters.value = ["reviewFilterValue<=6"];
    let filter = "reviewFilterValue>=";
    numericFilters.value.push(filter.concat(rating.toString()));
    selectedRating.value = rating;
  }
};

const props = defineProps<Props>();
const algoliaStore = useAlgoliaStore();
const { isMobile } = useDevice();
const showSearchBar = ref<boolean>(false);
const searchResultStore = useSearchResultStore();

const random_id = ref();
const activeTabs = reactive<Record<string, boolean>>({});
const showLess = reactive<Record<string, boolean>>({});

onMounted(() => {
  random_id.value = useRandomUUID();
  watch(
    () => [searchResultStore.selectedFilterState, selectedRating],
    () => {
      triggerFilterUpdate();
      scrollToTop();
      updatePageNumber();
    },
    { deep: true }
  );
});

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Optional for smooth scrolling
  });
};

const updatePageNumber = () => {
  if (algoliaStore.currentPage > 1) {
    algoliaStore.currentPage = 0;
  }
};

const handleClearAll = async () => {
  searchResultStore.resetFilters();
  selectedRating.value = null;
  numericFilters.value = null;
  await algoliaStore.handleFilterChange(algoliaStore.lastSearchedKeyword, false, []);
};

const shouldShowMore = (key: string) => {
  if (algoliaStore.filters) {
    const filter = algoliaStore.filters[key];
    return filter && Object.keys(filter).length > 5;
  }
};

const toggleShowMore = (key: string) => {
  showLess[key] = !showLess[key];
};

const toggleTab = (key: string) => {
  activeTabs[key] = !activeTabs[key];
};

const isChecked = (category: string, filter: string): boolean => {
  const filterString = `${category}:${filter}`;
  return searchResultStore.selectedFilterState.includes(filterString); // Check from the temp array
};

const triggerFilterUpdate = () => {
  algoliaStore.handleFilterChange(
    algoliaStore.lastSearchedKeyword,
    false,
    searchResultStore.transformSelectedFilters(),
    numericFilters.value
  );
};
</script>
